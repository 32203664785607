import React, { Fragment, useContext } from "react";
import Icofont from "react-icofont";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import AppContext from "./AppContext";
import { WelcomeOrderText } from "../Components/Texts.js";

export const OrderForm = (props) => {
	const context = useContext(AppContext);
	return (
		<Fragment>
			<section class="page-section portfolio mb-0" id="neworder">
				<div class="container text-center">
					<div class="text-center">
						<h2 class="page-section-heading text-secondary mb-3 d-inline-block">
							{context.lang.get === "en" ? WelcomeOrderText[0].title_DE : WelcomeOrderText[0].title_ENG}
						</h2>
					</div>

					<div class="divider-custom">
						<div class="divider-custom-line"></div>
						<div class="divider-custom-icon">
							<Icofont icon="icofont-tennis" />
							{/* <i class="fas fa-star"></i> */}
						</div>
						<div class="divider-custom-line"></div>
					</div>
					{/* <h4 class="masthead-heading text-secondary  mb-3">
						{context.lang.get === "en" ? props.WelcomeText[0].subtitle_DE : props.WelcomeText[0].subtitle_ENG}
					</h4>
					<p class="pre-wrap text-secondary text-center lead">
						{context.lang.get === "en" ? props.WelcomeText[0].text_DE : props.WelcomeText[0].text_ENG}
					</p> */}
				</div>

				<div class="container text-center" style={{ border: "0px solid #ffffff" }}>
					<div
						style={{
							position: "relative",
							height: "1230px",
							overflow: "hidden",
							maxWidth: "100%",
							paddingBottom: "6px",
						}}
					>
						<iframe
							title="form"
							src="https://form.jotformeu.com/60216741854354"
							style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
							frameborder="0"
							allowfullscreen
						></iframe>
					</div>
				</div>
			</section>
		</Fragment>
	);
};

export default OrderForm;
