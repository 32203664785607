import React, { Fragment, useContext } from "react";

import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import AppContext from "./AppContext";
import { OffersText1, OffersText2 } from "./Texts.js";

export const Offers = () => {
	const context = useContext(AppContext);
	return (
		<Fragment>
			<section class="page-section portfolio" id="offer">
				<div class="container">
					<div class="text-center">
						<h2 class="page-section-heading text-secondary mb-0 d-inline-block">
							{context.lang.get === "de" ? "OFFERS" : "ANGEBOT"}
						</h2>
					</div>

					<div class="divider-custom">
						<div class="divider-custom-line"></div>
						<div class="divider-custom-icon">
							<i class="fas fa-star"></i>
						</div>
						<div class="divider-custom-line"></div>
					</div>
				</div>
			</section>

			<Container class="row justify-content-center mb-5">
				<CardDeck style={{ marginBottom: "12px" }}>
					{OffersText1.map((item) => (
						<Card
							className="text-center"
							class="portfolio-item mx-auto"
							style={{ border: "none", textAlign: "center" }}
						>
							<Card.Img
								variant="top"
								src={item.image}
								style={{
									maxWidth: "200px",
									height: "auto",
									display: "block",
									marginLeft: "auto",
									marginRight: "auto",
									borderRadius: "50%",
								}}
							/>
							<Card.Body>
								<Card.Title>{context.lang.get === "en" ? item.title_DE : item.title_ENG}</Card.Title>
								<Card.Text>
									<h6>{context.lang.get === "en" ? item.subtitle_DE : item.subtitle_ENG}</h6>
									{context.lang.get === "en" ? item.text_DE : item.text_ENG}
								</Card.Text>
							</Card.Body>
						</Card>
					))}
				</CardDeck>
			</Container>
			<Container class="row justify-content-center mb-5">
				<CardDeck style={{ marginBottom: "60px" }}>
					{OffersText2.map((item) => (
						<Card class="portfolio-item mx-auto " style={{ border: "none", textAlign: "center" }}>
							<Card.Img
								variant="top"
								src={item.image}
								style={{
									maxWidth: "200px",
									height: "auto",
									display: "block",
									marginLeft: "auto",
									marginRight: "auto",
									borderRadius: "50%",
								}}
							/>
							<Card.Body>
								<Card.Title>{context.lang.get === "en" ? item.title_DE : item.title_ENG}</Card.Title>
								<Card.Text>
									<h6>{context.lang.get === "en" ? item.subtitle_DE : item.subtitle_ENG}</h6>
									{context.lang.get === "en" ? item.text_DE : item.text_ENG}
								</Card.Text>
							</Card.Body>
						</Card>
					))}
				</CardDeck>
			</Container>
		</Fragment>
	);
};

export default Offers;
