import React, { useContext, Fragment } from "react";
import { Helmet } from "react-helmet";
import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";

import Header from "../Components/Header";
//import Footer from "../Components/Footer";
import Offers from "../Components/Offers";
import Facts from "../Components/Facts";
//import StringingClub from "../Components/StringingClub";
import StringingPro from "../Components/StringingPro";
import Icofont from "react-icofont";

import Navigation from "../Components/Navigation";
import Copyrights from "../Components/Copyrights";
import AppContext from "../Components/AppContext";
import OrderForm from "../Components/OrderForm";

import {
	ProStrings,
	ClubStrings,
	WelcomTextProStrings,
	WelcomTextClubStrings,
	WelcomeContactText,
} from "../Components/Texts";

export const Home = (props) => {
	const context = useContext(AppContext);
	props.language === "en" ? context.lang.set("de") : context.lang.set("en");
	return (
		<Fragment>
			<Helmet>
				<html lang={props.language && props.language === "en" ? "en" : "de"} />
			</Helmet>
			<Navigation />
			<Header />
			<Offers />
			<Facts />
			<StringingPro Strings={ProStrings} WelcomeText={WelcomTextProStrings} SectionId="prostrings" />
			<StringingPro Strings={ClubStrings} WelcomeText={WelcomTextClubStrings} SectionId="clubstrings" />
			<OrderForm />
			<section class="page-section bg-primary text-white mb-0" id="contact">
				<div class="container">
					<div class="text-center">
						<h2 class="page-section-heading text-white d-inline-block mb-0">
							{context.lang.get === "en" ? WelcomeContactText[0].title_DE : WelcomeContactText[0].title_ENG}
						</h2>
					</div>

					<div class="divider-custom divider-light mb-3">
						<div class="divider-custom-line"></div>
						<div class="divider-custom-icon">
							<Icofont icon="icofont-ui-call" />
						</div>
						<div class="divider-custom-line"></div>
					</div>

					<div class="row justify-content-center">
						<div class="col-lg-4">
							<div class="d-flex flex-column align-items-center">
								<div class="icon-contact mb-3">
									<i class="fas fa-mobile-alt"></i>
								</div>
								<div class="text-white">Phone</div>
								<div class="lead font-weight-bold">(044) 586 71 77</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="d-flex flex-column align-items-center">
								<div class="icon-contact mb-3">
									<Icofont icon="icofont-home" />
								</div>
								<div class="text-white">Addresse</div>
								<div class="lead font-weight-bold text-center">
									CH-6006 <br></br>Luzern
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <Footer /> */}
			<Copyrights />
		</Fragment>
	);
};

export default Home;
