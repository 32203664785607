import React, { Fragment, useContext } from "react";

//import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Icofont from "react-icofont";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import AppContext from "./AppContext";
//import { ProStrings, WelcomTextProStrings } from "./Texts.js";

const styleImage = {
	maxWidth: "150px",
	height: "auto",
	display: "block",
	marginLeft: "auto",
	marginRight: "auto",
};

const styleRow = {
	display: "flex",
	textAlign: "center",
	marginBottom: "16px",
};

const styleColumn50 = {
	margin: "auto",
	textAlign: "center",
};

const styleRoundBorder = {
	borderRadius: "10px",
	border: "0px solid #7b9cc0",
	padding: "18px",
	marginBottom: "30px",
	backgroundColor: "#f6f6f6",
};

export const StringingPro = (props) => {
	const context = useContext(AppContext);
	return (
		<Fragment>
			<section class="page-section portfolio" id={props.SectionId}>
				<div class="container text-center">
					<div class="text-center">
						<h2 class="page-section-heading text-secondary mb-3 d-inline-block">
							{context.lang.get === "en" ? props.WelcomeText[0].title_DE : props.WelcomeText[0].title_ENG}
						</h2>
					</div>

					<div class="divider-custom">
						<div class="divider-custom-line"></div>
						<div class="divider-custom-icon">
							<Icofont icon="icofont-tennis" />
							{/* <i class="fas fa-star"></i> */}
						</div>
						<div class="divider-custom-line"></div>
					</div>
					<h4 class="masthead-heading text-secondary  mb-3">
						{context.lang.get === "en" ? props.WelcomeText[0].subtitle_DE : props.WelcomeText[0].subtitle_ENG}
					</h4>
					<p class="pre-wrap text-secondary text-center lead">
						{context.lang.get === "en" ? props.WelcomeText[0].text_DE : props.WelcomeText[0].text_ENG}
					</p>
				</div>
			</section>

			{props.Strings.map((item) => (
				<Fragment class="mb-3">
					<Container style={styleRoundBorder} class="mb-5">
						<Row>
							<Col sm={12} md={5} large={3}>
								<div style={styleRow}>
									<div style={styleColumn50}>
										<Image variant="top" src={item.image1} style={styleImage} fluid />
									</div>

									{item.image2 ? (
										<div style={styleColumn50}>
											<Image variant="top" src={item.image2} style={styleImage} fluid />
										</div>
									) : null}
								</div>
							</Col>
							<Col sm={12} md={7} large={9}>
								<h4 class="mb-3 text-secondary">
									{context.lang.get === "de" ? item.string_ENG : item.string_DE}
								</h4>
								<div class="text-secondary mb-3">
									{context.lang.get === "en"
										? item.desc_DE.split("\n").map((item, i) => (
												<div class="mb-3" key={i}>
													{item}
												</div>
										  ))
										: item.desc_ENG.split("\n").map((item, i) => (
												<div class="mb-3" key={i}>
													{item}
												</div>
										  ))}
								</div>
								<h5 class="text-secondary">CHF {item.price ? item.price : null}</h5>
								<Button href="#neworder">
									{context.lang.get === "de" ? "     ORDER     " : "   BESTELLEN   "}
								</Button>
							</Col>
						</Row>
					</Container>
				</Fragment>
			))}
		</Fragment>
	);
};

export default StringingPro;
