import BabolatRpmBlast16 from "../Assets/Img/Strings/BabolatRPMBlast100_300_350.png";
import BabolatVSTeam from "../Assets/Img/Strings/BabolatVsTeamNaturalGut17_300_350.png";
import BabolatVSTouch from "../Assets/Img/Strings/BabolatVsTouchNaturalGut15_300_350.png";
import HeadHawkTouch from "../Assets/Img/Strings/HeadHawkTouch_300_350.png";
import Luxilon4G from "../Assets/Img/Strings/Luxilon4g_300_350.png";
import LuxilonAluPower from "../Assets/Img/Strings/LuxilonAluPower_300_350.png";
import LuxilonAluPowerRough from "../Assets/Img/Strings/LuxilonAluPowerRough_300_350.png";
import LuxilonElement from "../Assets/Img/Strings/LuxilonElement_300_350.png";
import WilsonNaturalGut16 from "../Assets/Img/Strings/WilsonNaturalGut15_300_350.png";
import WilsonSensation16 from "../Assets/Img/Strings/WilsonSensation16_300_350.png";
import YonexPolytourPro125 from "../Assets/Img/Strings/YonexPolytourPro125_300_350.png";
import TecnifibleRazorPro from "../Assets/Img/Strings/TecnifibleRazorCodeWhite300_350.png";
import Offer1 from "../Assets/Img/Offers/offer1.jpg";
import Offer2 from "../Assets/Img/Offers/offer2.jpg";
import Offer3 from "../Assets/Img/Offers/Location.jpg";
import Offer4 from "../Assets/Img/Offers/offer4.jpg";

export const MenuOptions = [
	{
		string_DE: "ANGEBOT",
		string_ENG: "OFFERS ",
		ancor: "#offer",
	},
	{
		string_DE: "PRO BESAITUNG",
		string_ENG: "PRO STRINGING",
		ancor: "#prostrings",
	},
	{
		string_DE: "EINZELSEITEN",
		string_ENG: "SELECTED STRINGS",
		ancor: "#clubstrings",
	},
	{
		string_DE: "FAKTEN",
		string_ENG: "FACTS",
		ancor: "#facts",
	},
	{
		string_DE: "NEUE BESTELLUNG",
		string_ENG: "   NEW ORDER   ",
		ancor: "#neworder",
	},
];

export const BannerText = [
	{
		title_DE: "TENNIS BESAITUNG",
		title_ENG: "TENNIS STRINGING",
		subtitle_DE: "24 Stunden Bespannung Besaitung für Tennis-Schläger",
		subtitle_ENG: "24 Hour Stringing and Tunning for Tennis Rackets",
		text_DE:
			"New: Tecnifibre Razor Code auf Lager. \nGratis Abholung in Luzern, Adliswil, Thalwil, Horgen, Wädenswil, Richterswil",
		text_ENG:
			"News: Tecnifibre Razor Code in stock. \nFree pick-up is available in Luzern, Adliswil, Thalwil, Horgen, Wädenswil, Richterswil",
	},
];

export const WelcomeText = [
	{
		title_DE: "WELCOME",
		title_ENG: "WELCOME ",
		subtitle_DE: "Professioneller Besaitungsservice für Tennisschläger",
		subtitle_ENG: "We Offer Professional Tennis Stringing Services",
		text_DE:
			"Spielen Sie Tennis?? \nSpielen Ihre Kinder im Tennis Turnieren? \nWollen Sie mehr Matches zu gewinnen? \nDann brauchen Sie gute Saiten!",
		text_ENG:
			"Do you play tennis? \nDo your children play in tennis tournaments? \nWould you like to win more tennis matches? \nThen you need good strings in your racket!",
	},
];

export const WelcomeFactsText = [
	{
		title_DE: "INTERESSANTE FAKTEN",
		title_ENG: "INTERESTING FACTS ",
	},
];

export const WelcomeOrderText = [
	{
		title_DE: "NEUE BESTELLUNG",
		title_ENG: "NEW ORDER",
	},
];

export const WelcomeContactText = [
	{
		title_DE: "KONTAKT",
		title_ENG: "CONTACT",
	},
];

export const FactsText = [
	{
		image: "icofont-question",
		title_DE: "WIE OFT SOLLTE ICH  SAITEN WECHSELN?",
		title_ENG: "HOW OFTEN SHOULD I REPLACE STRINGS?",
		text_DE:
			"Man sollte den Tennisschläger so oft im Jahr besaiten, wie man Tennistage in der Woche auf dem Tennisplatz verbringt. \nDie jeweilige Spieldauer spielt dabei natürlich auch eine wesentliche Rolle. \nSpielen Sie als einmal pro Woche, sollten Sie Ihren Tennisschläger mindestens einmal pro Saison besaiten.",
		text_ENG:
			"A general rule of thumb is that you should restring as many times per year as you play per week. \nIf you play three times per week, then you should restring your racquet three times per year. \nHowever, competitive players should restring more often.",
	},
	{
		image: "icofont-clock-time",
		title_DE: "ALLGEMEIN WISSENWERTES",
		title_ENG: "GENERAL FACTS",
		text_DE:
			"Die Bespannungshärte sich innerhalb der ersten 24 Stunden um bis zu 10% verringert. \nDie genaue Höhe des Verlustes hängt dabei vom Saitentyp ab. \nSpieler, die mit starkem Spin spielen, sollten ihre Schläger häufiger bespannen als Spieler mit flachen Schlägen.",
		text_ENG:
			"In the first 24 hours after stringing, strings can lose roughly 10% of their tension. \nThis reduction in tension continues as time goes by. \nPlayers with heavy spin should restring their rackets more frequently, since the friction and the wear of your strings is greater than for players with flat shots.",
	},
	{
		image: "icofont-ui-cut",
		title_DE: "SAITEN NICHT GERISSEN, SOLLTE ICH WECHSELN?",
		title_ENG: "SHOULD I WAIT UNTIL MY STRINGS BREAK?",
		text_DE:
			"Die Saitenspannung und die Art der verwendeten Saite haben einen enormen Einfluss auf Ihre Technik, auf Ihre Fähigkeit, Geschwindigkeit und Platzierung zu kontrollieren, sowie auf das Wohlbefinden Ihres Körpers (insbesondere Schulter- und Ellbogengelenke). \nDas Auffrischen Ihres Tennisschlägers mit frischen flexiblen Saiten ist hilfreich für die Gesundheit Ihrer Arme und Schultern und kann Ihnen auch dabei helfen, besser zu spielen. ",
		text_ENG:
			"String tension, and the type of string you are using, have a tremendous effect on your technique, ability to control speed and placement, as well as the well-being of your body (specifically shoulder and elbow joints). \nRestringing your tennis racquet with fresh flexible strings is beneficial for you arm and shoulder, it can also help you play better. ",
	},
];

export const OffersText1 = [
	{
		id: 1,
		image: Offer1,
		title_DE: "24 STUNDEN TENNIS BESAITUNG",
		title_ENG: "24 HOUR TENNIS STRINGING SERVICE ",
		subtitle_DE: "FÜR ERWACHSENE UND KINDER SCHLÄGER",
		subtitle_ENG: "FOR JUNIOR AND ADULT TENNIS RACKETS ",
		text_DE: "Ich biete einen 24 Stunden Besaitungsservice für Tennisschläger an.",
		text_ENG:
			"I offer 24 hour stringing and tuning services for tennis rackets. I string with modern and precise stringing methods and best practices.",
	},
	{
		id: 2,
		image: Offer2,
		title_DE: "HÖCHSTE QUALITÄT",
		title_ENG: "PROFESSIONAL QUALITY",
		subtitle_DE: "PROFESSIONELLE BESAITUNG",
		subtitle_ENG: "PROFESSIONAL EQUIPMENT",
		text_DE:
			'Bei der Besaitung beachte ich die präzisesten und rahmenschonendsten Methoden und eine "Pro" elektronische Besaitungsmaschine. ',
		text_ENG:
			"I am using professional grade stringing equipment! My professional electronic stringing machine delivers precise results. ",
	},
];

export const OffersText2 = [
	{
		id: 3,
		image: Offer3,
		title_DE: "LOKALE SERVICE",
		title_ENG: "LOCATIONS",
		subtitle_DE: "FREI LOKALE ABHOLUNG UND LIEFERUNG",
		subtitle_ENG: "FREE LOCAL PICKUP AND DELIVERY",
		text_DE:
			"Kostenlose Schlägerabholung an den folgenden Orten: Luzern, Adliswil, Thalwil, Horgen, Wädenswil, Richterswil. Gerne können Sie auch ihre Schläger zum Besaiten in Luzern bringen!",
		text_ENG:
			"Free racket pickup in the following locations (weekdays): Luzern, Adliswil, Thalwil, Horgen, Wädenswil, Richterswil. You can bring your rackets for stringing to Luzern any day!",
	},
	{
		id: 4,
		image: Offer4,
		title_DE: "PREISGÜNSTIG",
		title_ENG: "AFFORDABLE PRICE",
		subtitle_DE: "GELD SPAREN!",
		subtitle_ENG: "SAVE MONEY!",
		text_DE:
			"Niedrigster Preis in der Schweiz für Tennisschläger Besaitung! Ich biete beste Leistung Saiten zu guten Preisen!",
		text_ENG:
			"Lowest price in Switzerland for stringing tennis rackets! Top performance tennis strings at reasonable prices!",
	},
];

export const WelcomTextProStrings = [
	{
		title_DE: "PROFI BESAITUNG",
		title_ENG: "PRO STRINGING",
		subtitle_DE:
			"In dieser Sektion bieten wir Pro-Tennissaiten an, diese sind bevorzugte Saiten der besten Tennisspieler!",
		subtitle_ENG:
			"In this section we offer selected pro tennis stringing options, these are preferred strings of the top ATP and WTA tennis players!",
		text_DE: "Alle Saiten sind inklusive mit Bespannungsservice!",
		text_ENG: "All options include stringing service!",
	},
];

export const WelcomTextClubStrings = [
	{
		title_DE: "CLUB UND FREIZEITSPIELER BESAITUNG",
		title_ENG: "CLUB AND RECREATIOINAL PLAYERS",
		subtitle_DE:
			"Saiten für Club- und Freizeittennisspieler",
		subtitle_ENG:
			"Recommended strings for club and recreational tennis players",
		text_DE: "Alle Saiten sind inklusive mit Bespannungsservice!",
		text_ENG: "All options include stringing service!",
	},
];

export const ClubStrings = [
	{
		string_DE: "Babolat RPM Blast 125 Tennissaite",
		string_ENG: "Babolat RPM Blast 125",
		desc_DE:
			"Babolat RPM Blast 16 ist die bevorzugte Saite für Rafael Nadal und Carlos Alcaraz. Viele andere professionelle Tour-Spieler spielen mit Versionen von Babolat RPM Blast. Diese Saite ist sehr spinfreundlich. \nInklusive Bespannungsservice.",
		desc_ENG:
			"The Babolat RPM Blast 16 is the string of choice for Rafael Nadal and Carlos Alcaraz. Many other professional players play with versions of Babolat RPM Blast. This string is very spin friendly.\n\nIncludes stringing service.",
		price: 40,
		image1: BabolatRpmBlast16,
		image2: null,
	},
	{
		string_DE: "Tecnifibre Razor Code White 1.25",
		string_ENG: "Tecnifibre Razor Code White 1.25",
		desc_DE:
			"Razor Code spielt sich weicher als traditionelle Polysaiten. Sie hat mehr Kraft und absorbiert Stösse und Vibrationen gut, wodurch die Spielbarkeit verbessert wird. Razor Code ist die kontrollorientierteste Polyestersaite von Tecnifibre. Sie hält die Spannung wirklich gut. Tecnifibre Razor Code ist die wahre „nächste Generation“ der Poly-Saiten-Technologie, und ihre Vorteile sind nicht nur für Spieler auf Tour-Niveau, sondern für alle hart schlagenden Topspin-Spieler, die nach mehr Komfort, Power und Haltbarkeit suchen. \nInklusive Bespannungsservice.",
		desc_ENG:
			"Razor Code plays softer than traditional poly strings. It has more power and absorbs shock and vibration well, providing improved play-ability. Razor Code is Tecnifibre's most control-oriented polyester string. It maintains tension really well. Tecnifibre Razor Code is the true “next generation” of poly string technology, and its benefits are not just for tour-level players, but for all hard-hitting topspin players looking for more comfort, power and durability. \n\nIncludes stringing service.",
		price: 50,
		image1: TecnifibleRazorPro,
		image2: null,
	},
	{
		string_DE: "Yonex Poly Tour Pro 125 Yellow Tennissaite",
		string_ENG: "Yonex Poly Tour Pro 125 Yellow",
		desc_DE:
			"Yonex Poly Tour Pro ist eine weichere Poly-Saite. Diese Saite ist sehr spinfreundlich. Powerspieler werden die Kontrolle und das Gefühl, das sie von dieser Saite bekommen, lieben. Eine gute, haltbare Saite, die eine gute Wahl für aggressive, schlagkräftige Spieler ist. \nInklusive Bespannungsservice.",
		desc_ENG:
			"Yonex Poly Tour Pro is a softer playing poly string. This string is very spin-friendly. Power players will love the control and feel they get from all areas of the court. A solid, durable string that is a great choice for aggressive, big hitting players.\n\nIncludes stringing service.",
		price: 40,
		image1: YonexPolytourPro125,
		image2: null,
	},
	{
		string_DE: "Head Hawk Touch 1.25mm Tennisseite",
		string_ENG: "Head Hawk Touch 1.25mm",
		desc_DE:
			"Gut Spielgefühl, Balance zwischen Kraft und Kontrolle, Elastizität für präzise Rückmeldung. \nInklusive Bespannungsservice.",
		desc_ENG:
			"This is a slightly softer version of the original Hawk. This string is firm enough to deliver exceptional control, but also muted enough to reduce the harshest vibrations. The low-powered response allows big hitters to take huge cuts at the ball while delivering volumes of spin. This is a great option for strong intermediate and advanced level players who are looking for a very precise and accurate response.\n\nIncludes stringing service.",
		price: 50,
		image1: HeadHawkTouch,
		image2: null,
	},
	{
		string_DE: "Luxilon Big Banger Alu Power 16L 1.25mm Tennissaite",
		string_ENG: "Luxilon Big Banger Alu Power 16L 1.25mm",
		desc_DE:
			"Die beliebteste Saite auf der ATP Pro Tour, unter anderem von Marin Cilic und viele andere bevorzugt. Diese Saite bietet beeindruckende Power und gute Kontrolle. Spieler mit einem langsameren Schwungstil kommt diese Saite bei Spinschlägen sehr entgegen. \nInklusive Bespannungsservice.",
		desc_ENG:
			"Luxilon is the most popular choice on the ATP Tour. This string allows a player to take big cuts at the ball without losing control. These strings produce massive stroke speed and are very spin friendly. ALU Rough is textured to further enhance spin production. This string is used by many ATP professional players includes Martin Cilic and many others.\n\nIncludes stringing service.",
		price: 50,
		image1: LuxilonAluPower,
		image2: null,
	},
	{
		string_DE: "Luxilon Element 1.25mm Tennissaite",
		string_ENG: "Luxilon Element 16L 1.25mm",
		desc_DE:
			"Die Element ist eine der weichsten und komfortabelsten Saiten aus Luxilon. Eine grossartige, kontrollorientierte Saite mit überdurchschnittlichem Touch und Komfort. \nInklusive Bespannungsservice.",
		desc_ENG:
			"Element features Multi-Mono Technology, providing you with a more flexible feel than Luxilon monofilaments. This string is a great option for players looking for a durable control string with great feel and comfort.\n\nIncludes stringing service.",
		price: 50,
		image1: LuxilonElement,
		image2: null,
	},
	{
		string_DE: "Luxilon Big Banger Alu Power und Element Hybridsaiten",
		string_ENG: "Luxilon Big Banger Alu Power and Element Hybrid",
		desc_DE:
			"Alu Power und Element sind die beliebteste Hybridsaiten von besaitung.ch Wir haben diese Kombination ausgewählt, nachdem wir viele andere Optionen getestet haben. Diese Hybrid Saiten bieten Power, Kontrolle, Komfort und Touch fast auf dem Niveau einer Hybridsaite mit Naturdarm. Die Hauptvorteile sind: Diese Saiten halten länger und sind günstiger als Naturdarm. \nInklusive Bespannungsservice.",
		desc_ENG:
			"Luxilon Alu Power and Element is the most popular hybrid set of our players. We have selected this combination after testing numerous pairs of strings from top brands. This combination provide volumes of power together with the level of feel and control that could be compaired only to the natural gut hybrids.  The biggest advantage of this set: it is very durable and much more affordable compared to the natural gut options. Try it, you will not be disappointed!\n\nIncludes stringing service.",
		price: 50,
		image1: LuxilonAluPower,
		image2: LuxilonElement,
	},
	{
		string_DE: "Luxilon Alu Power Rough 16L 1.25mm Tennissaite",
		string_ENG: "Luxilon Alu Power Rough 16L 1.25mm",
		desc_DE:
			"Die Alu Power Seite verfügt über eine strukturierte, angeraute Oberfläche, die spürbar präziseren Spin auf den Ball überträgt. Für den geschulten Spieler, der Spin und Power verlangt. \nInklusive Bespannungsservice.",
		desc_ENG:
			"This is the textured version of Alu Power. It has a rough surface to help the strings spin the ball. Like the standard version, this string has incredible feel for a firm co-polymer monofilament. This string has become famous because it has been used in a hybrid by some of the greatest tennis players of all time.\n\nIncludes stringing service.",
		price: 55,
		image1: LuxilonAluPowerRough,
		image2: null,
	},
];

export const ProStrings = [
	{
		string_DE: "“Roger Federer” Tennissaiten",
		string_ENG: "“Roger Federer” Strings",
		desc_DE:
			"Wilson Naturdarmsaite Längssaite / Luxilon Big Banger Alu Power Rough Quersaite. \nInklusive Bespannungsservice.",
		desc_ENG:
			"Wilson Natural Gut main strings / Luxilon Big Banger Alu Power Rough cross strings.\n\nIncludes stringing service.",
		price: 80,
		image1: WilsonNaturalGut16,
		image2: LuxilonAluPowerRough,
	},

	{
		string_DE: "“Novak Djokovic” Tennissaiten",
		string_ENG: "“Novak Djokovic” Strings",
		desc_DE:
			"Babolat VS Team Naturdarmsaite Längssaite / Luxilon Big Banger Alu Power Rough Quersaite. \nInklusive Bespannungsservice.",
		desc_ENG:
			"Babolat VS Team Natural Gut main strings / Luxilon Big Banger Alu Power Rough cross strings. \nIncludes stringing service.",
		price: 70,
		image1: BabolatVSTeam,
		image2: LuxilonAluPowerRough,
	},

	{
		string_DE: "“Rafael Nadal” und “Carlos Alcaraz” Tennissaite",
		string_ENG: "“Rafael Nadal” and “Carlos Alcaraz” Strings",
		desc_DE: "Babolat RPM Blast Saiten. Diese Saite ist sehr spinfreundlich. \nInklusive Bespannungsservice.",
		desc_ENG: "Babolat RPM Blast strings. \nIncludes stringing service.",
		price: 40,
		image1: BabolatRpmBlast16,
		image2: null,
	},
	// {
	// 	string_DE: "“Dominic Thiem” Tennissaite",
	// 	string_ENG: "The “Dominic Thiem” Strings",
	// 	desc_DE:
	// 		"Babolat VS Touch Naturdarmsaite Längssaite / Babolat RPM Blast Rough Quersaite. \nInklusive Bespannungsservice.",
	// 	desc_ENG: "Babolat VS Touch Natural Gut / Babolat RPM Blast Rough . \nIncludes stringing service.",
	// 	price: 60,
	// 	image1: BabolatVSTouch,
	// 	image2: BabolatRpmBlast16,
	// },
	{
		string_DE: "“Daniil Medvedev” und “Iga Swiatek” Tennissaiten",
		string_ENG: "“Daniil Medvedev” and “Iga Swiatek” Strings",
		desc_DE:
			"Tecnifibre Razor Code White Saiten. \nRazor Code spielt sich weicher als traditionelle Polysaiten. Sie hat mehr Kraft und absorbiert Stösse und Vibrationen gut, wodurch die Spielbarkeit verbessert wird. Razor Code ist die kontrollorientierteste Polyestersaite von Tecnifibre. \nInklusive Bespannungsservice.",
		desc_ENG:
			"Tecnifibre Razor Code White strings. \nRazor Code plays softer than traditional poly strings. It has more power and absorbs shock and vibration well, providing improved play-ability. Razor Code is Tecnifibre's most control-oriented polyester string. \nIncludes stringing service.",
		price: 50,
		image1: TecnifibleRazorPro,
		image2: null,
	},
	{
		string_DE: "“Alexander Zverev” Tennisseiten",
		string_ENG: "“Alexander Zverev” Strings",
		desc_DE: "Head Hawk Touch Längssaite / Babolat VS Touch Quersaite. \nInklusive Bespannungsservice",
		desc_ENG: "Head Hawk Touch main strings / Babolat VS Touch cross strings. \nIncludes stringing service.",
		price: 70,
		image1: HeadHawkTouch,
		image2: BabolatVSTouch,
	},
	
	{
		string_DE: "“Belinda Bencic” Tennissaiten",
		string_ENG: "“Belinda Bencic” Strings",
		desc_DE: "Yonex Poly Tour Pro Längssaite / Babolat VS Touch Quersaite. \nInklusive Bespannungsservice.",
		desc_ENG: "Yonex Poly Tour Pro main strings / Babolat VS Touch cross strings. \nIncludes stringing service.",
		price: 70,
		image1: YonexPolytourPro125,
		image2: BabolatVSTouch,
	},
];

export default MenuOptions;
