import React, { useContext, Fragment } from "react";
//import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavbarBrand from "react-bootstrap/NavbarBrand";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import MainLogo from "../Assets/Img/Logo-stringing-180-white.png";
//import MainLogo from "../Assets/Img/MainLogo.jpg";
//import Favicon from "../Assets/Img/favicon.ico";

import { MenuOptions } from "./Texts";
import AppContext from "./AppContext";

const LanguagePicker = () => {
	const context = useContext(AppContext);
	return (
		<Button variant="outline-light" href={context.lang.get === "de" ? "/" : "/index-en.htm"}>
			{context.lang.get === "de" ? "De" : "En"}
		</Button>
	);
};

const Navigation = () => {
	const context = useContext(AppContext);
	return (
		<Fragment>
			<div></div>
			<Navbar id="navbar" bg="secondary" collapseOnSelect expand="lg" variant="dark" sticky="top">
				<Navbar.Brand href="#home" style={{ width: "230px" }}>
					<img
						alt=""
						src={MainLogo}
						width="30"
						height="30"
						class="float-left"
						style={{ margin: " 6px 12px 0px 6px" }}
					/>{" "}
					<h5 className="align-middle float-left" style={{ padding: "6px 12px 0px 0px" }}>
						BESAITUNG.CH
					</h5>
					<Form inline>
						<LanguagePicker />
					</Form>
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="ml-auto">
						{MenuOptions.map((item) => (
							<Nav.Link href={item.ancor}>
								<h6 className="align-bottom" style={{ padding: "6px 0px 0px 0px" }}>
									{context.lang.get === "en" ? item.string_DE : item.string_ENG}
								</h6>
							</Nav.Link>
						))}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			{/* <Navbar bg="secondary" variant="dark" expand="lg" sticky="top">
				<NavbarBrand href="#home" className={"navbar-brand js-scroll-trigger"}>
					<img
						alt=""
						src={MainLogo}
						width="35"
						height="35"
						class="float-left"
						style={{ margin: " 6px 12px 6px 6px" }}
					/>{" "}
					<h3 className="align-middle" style={{ padding: "6px 0px 6px 0px" }}>
						BESAITUNG.CH
					</h3>
				</NavbarBrand>
				<NavbarToggle aria-controls="basic-navbar-nav" />
				<NavbarCollapse id="basic-navbar-nav">
					<Nav className="navbar-nav ml-auto" style={{ padding: "0px 24px 0px 0px" }}>
						{MenuOptions.map((item) => (
							<Nav.Link href={item.ancor} className={"nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"}>
								<h6 className="align-bottom" style={{ padding: "12px 0px 0px 0px" }}>
									{context.lang.get === "en" ? item.string_DE : item.string_ENG}
								</h6>
							</Nav.Link>
						))}
					</Nav>
					<Form inline>
						<LanguagePicker />
					</Form>
				</NavbarCollapse>
			</Navbar> */}
		</Fragment>

		// <Container className={"navbar navbar-expand-lg bg-secondary fixed-top"} id="mainNav">
	);
};

export default Navigation;
