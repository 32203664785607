import React, { Fragment, useContext } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
//import Image from "react-bootstrap/Image";
//import Button from "react-bootstrap/Button";
import MainImage1 from "../Assets/Img/MainImage01.jpg";
import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import AppContext from "./AppContext";
import { WelcomeText, BannerText } from "./Texts.js";
import Icofont from "react-icofont";

const divStyle = {
	backgroundImage:
		"linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,0.25), rgba(0, 0, 0, 0.85)), url(" +
		MainImage1 +
		")",
	height: "600px",
	backgroundPosition: "center",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	position: "relative",
};

const heroText = {
	textAlign: "center",
	position: "absolute",
	top: "70%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	color: "white",
};

const Header = () => {
	const context = useContext(AppContext);

	return (
		<Fragment>
			<header class="bg-primary text-white text-center" id="home">
				{/* <header class="masthead bg-primary text-white text-center"> */}
				<Jumbotron style={divStyle} fluid>
					<div style={heroText}>
						<h1 class="mb-3" style={{ fontSize: "70px" }}>
							{context.lang.get === "en" ? BannerText[0].title_DE : BannerText[0].title_ENG}
						</h1>
						<h4 class="mb-3">
							{context.lang.get === "en" ? BannerText[0].subtitle_DE : BannerText[0].subtitle_ENG}
						</h4>
						{context.lang.get === "en"
							? BannerText[0].text_DE.split("\n").map((item, i) => (
									<h5 class="mb-3" key={i}>
										{item}
									</h5>
							  ))
							: BannerText[0].text_ENG.split("\n").map((item, i) => (
									<h5 class="mb-3" key={i}>
										{item}
									</h5>
							  ))}
						{/* <p>
							<Button variant="primary">Learn more</Button>
						</p> */}
					</div>
				</Jumbotron>
				<div class="container d-flex align-items-center flex-column" back>
					{/* <Image src={MainImage1} fluid /> */}
					{/* <img class="masthead-avatar mb-5" src="assets/img/avataaars.svg" alt="" /> */}
					{/* Masthead Heading*/}
					<h1 class="masthead-heading mb-3">
						{context.lang.get === "en" ? WelcomeText[0].title_DE : WelcomeText[0].title_ENG}
					</h1>
					<h4 class="masthead-heading mb-3">
						{context.lang.get === "en" ? WelcomeText[0].subtitle_DE : WelcomeText[0].subtitle_ENG}
					</h4>
					{/* Icon Divider*/}
					<div class="divider-custom divider-light">
						<div class="divider-custom-line"></div>
						<div class="divider-custom-icon">
							{/* <i class="fas fa-star"></i> */}
							<Icofont icon="icofont-tennis-player" size="2" />
						</div>
						<div class="divider-custom-line"></div>
					</div>
					{/* Masthead Subheading*/}
					<p class="pre-wrap mb-5">
						{context.lang.get === "en" ? WelcomeText[0].text_DE : WelcomeText[0].text_ENG}
					</p>
				</div>
			</header>
		</Fragment>
	);
};
export default Header;
