import React, { Fragment, useContext } from "react";

import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import AppContext from "./AppContext";
import { FactsText, WelcomeFactsText } from "./Texts.js";
import Icofont from "react-icofont";
import { Row, Col } from "react-bootstrap";

export const Facts = () => {
  const context = useContext(AppContext);
  return (
    <Fragment>
      <section class="page-section bg-primary text-white mb-0" id="facts">
        <div class="container">
          {/* About Section Heading*/}
          <div class="text-center">
            <h2 class="page-section-heading d-inline-block text-white">
              {context.lang.get === "en" ? WelcomeFactsText[0].title_DE : WelcomeFactsText[0].title_ENG}
            </h2>
          </div>
          {/* Icon Divider*/}
          <div class="divider-custom divider-light mb-5">
            <div class="divider-custom-line"></div>
            <div class="divider-custom-icon">
              <i class="fas fa-star"></i>
            </div>
            <div class="divider-custom-line"></div>
          </div>
          <Container class="row justify-content-center mb-5">
            {/* <CardDeck style={{ marginBottom: "12px" }}> */}
            <Row>
              {FactsText.map((item) => (
                <Col md={6} lg={4}>
                  <Card
                    className="text-center"
                    class="text-white mx-auto"
                    style={{ border: "none", textAlign: "center", backgroundColor: "transparent" }}
                  >
                    <Icofont icon={item.image} size="6" />
                    <Card.Body>
                      <Card.Title style={{ height: "60px" }}>
                        {context.lang.get === "en" ? item.title_DE : item.title_ENG}
                      </Card.Title>
                      <Card.Text>
                        {/* <h6>{context.lang.get === "en" ? item.subtitle_DE : item.subtitle_ENG}</h6> */}
                        {context.lang.get === "en"
                          ? item.text_DE.split("\n").map((item, i) => (
                              <div class="mb-3" key={i}>
                                {item}
                              </div>
                            ))
                          : item.text_ENG.split("\n").map((item, i) => (
                              <div class="mb-3" key={i}>
                                {item}
                              </div>
                            ))}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            {/* </CardDeck> */}
          </Container>
          {/* About Section Content*/}
          {/* <div class="row">
						<div class="col-lg-4 ml-auto">
							<p class="pre-wrap lead">
								Freelancer is a free bootstrap theme created by Start Bootstrap. The download includes the
								complete source files including HTML, CSS, and JavaScript as well as optional SASS stylesheets
								for easy customization.
							</p>
						</div>
						<div class="col-lg-4 mr-auto">
							<p class="pre-wrap lead">
								You can create your own custom avatar for the masthead, change the icon in the dividers, and
								add your email address to the contact form to make it fully functional!
							</p>
						</div>
					</div> */}
        </div>
      </section>
    </Fragment>
  );
};

export default Facts;
